import React from 'react'
import Link from 'gatsby-link'
import Layout from './Layout'
import NewProject from './Form/new-project'
import Lightbox from 'lightbox-react'
import ProjectList from './Profile/ProjectList'
import ConvertingDesigns from './Profile/ConvertingDesigns'
import blankProfile from '../../static/img/blank_profile.jpg'
import hditThumb from '../../static/img/HDIT_Thumb.jpg'
import newProjectButton from '../../static/img/new-project-button.jpg'
import ErrorMessage from './ErrorMessage'
import { orderBy, filter } from 'lodash'
import { navigate } from '@reach/router'
import { getProfileImage, getUserInfo } from '../utils/user'
import { createProject, getProjects, deleteProject } from '../utils/project'
import { createDesign } from '../utils/design'
// import { S3Image } from 'aws-amplify-react'

class Profile extends React.Component {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(props.location.search)
    let queryUser = null
    if (params.get('userid')) {
      queryUser = params.get('userid')
    }
    this.state = {
      lightBoxOpen: false,
      newProjectName: '',
      newProjectCompany: '',
      myprojects: [],
      myprojectsFiltered: [],
      loadingProjects: true,
      userInfo: null,
      beginConversion: false,
      convertButton: false,
      queryUser: queryUser,
      error: null,
      skip: 0,
      limit: 100,
      moreProjects: true,
    }
  }

  componentDidMount() {
    try {
      this.loadProjects(
        this.state.queryUser ? this.state.queryUser : null,
        true
      )
      getUserInfo()
        .then(
          function (userInfo) {
            this.setState({
              userInfo: userInfo,
            })
          }.bind(this)
        )
        .catch(function (err) {
          console.log(err)
        })
    } catch (err) {
      this.setState({
        error: err,
      })
    }
  }

  loadProjects = (userid, clean) => {
    getProjects(userid, this.state.skip, this.state.limit)
      .then(
        function (data) {
          let newData = data
          if (!clean) {
            newData = this.state.myprojects.concat(newData)
          }
          console.log({data: newData, skip: this.state.skip, limit: this.state.limit})
          let moreProjects = true
          if (data.length === 0 || data.length < this.state.limit) {
            moreProjects = false
          }
          this.setState({
            myprojects: newData,
            myprojectsFiltered: newData,
            loadingProjects: false,
            moreProjects: moreProjects,
          })
        }.bind(this)
      )
      .catch(function (err) {
        console.log(err)
      })
  }

  openDrupalDataImport = () => {
    this.setState({
      beginConversion: true,
    })
  }

  updateProjectName = (event) => {
    if (event.target.name === 'companyName') {
      this.setState({
        newProjectCompany: event.target.value,
      })
    } else {
      this.setState({
        newProjectName: event.target.value,
      })
    }
  }

  submitNewProject = (e) => {
    e.preventDefault()
    let newProject = {
      name: this.state.newProjectName,
      company: this.state.newProjectCompany,
    }
    createProject(newProject)
      .then(function (project) {
        navigate('/design/' + project.designs[0]._id)
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  handleDelete = (project) => {
    deleteProject(project._id)
      .then(
        function (data) {
          this.loadProjects(this.state.queryUser ? this.state.queryUser : null, true)
        }.bind(this)
      )
      .catch(function (err) {
        console.log(err)
      })
  }

  handleCreateDesign = (project) => {
    createDesign(project._id, project.designs.length + 1)
      .then(function (newDesign) {
        navigate('/design/' + newDesign._id)
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  sortProjects = (field, direction) => {
    var projects = orderBy(this.state.myprojectsFiltered, [field], direction)
    this.setState({
      myprojectsFiltered: projects,
    })
  }

  filterProjects = (e) => {
    var val = e.target.value
    var projects = []
    if (val) {
      projects = filter(this.state.myprojectsFiltered, function (o) {
        if (o.name) {
          return o.name.toLowerCase().includes(val.toLowerCase())
        } else {
          return false
        }
      })
    } else {
      projects = this.state.myprojects
    }
    if (projects) {
      this.setState({
        myprojectsFiltered: projects,
      })
    }
  }

  render() {
    let profileImage = blankProfile
    if (getProfileImage()) {
      profileImage = getProfileImage()
    }
    let convertButton = null
    if (this.state.convertButton) {
      convertButton = (
        <div style={{ textAlign: 'right' }}>
          <h5>DATA IMPORT</h5>
          <button
            onClick={this.openDrupalDataImport}
            className="button"
            style={{ margin: '0px', cursor: 'pointer' }}
          >
            IMPORT LEGACY DATA
          </button>
        </div>
      )
    }
    return (
      <Layout>
        {this.state.error && (
          <ErrorMessage
            message={this.state.error.message}
            link="/"
            linkMessage="Go back to the home page"
          />
        )}
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <h3>
              FAST-TRACK THE DESIGN PROCESS! my.EDS lets you create and manage
              Elevator Design Studio projects, designs, and your profile
              information from one spot.
            </h3>
            <div className="row">
              <div className="col-xs-5">
                <h5>MY PROFILE</h5>
                <div className="row">
                  <div className="col-lg-5">
                    <img
                      src={profileImage}
                      style={{
                        border: 'solid 1px',
                        objectFit: 'cover',
                        display: 'block',
                        width: '156px',
                        height: '156px',
                      }}
                      alt="profile"
                    />
                  </div>
                  <div className="col-lg-7">
                    <Link to="/profile-edit" className="smallLink">
                      <i className="las la-edit"></i>EDIT USERNAME/PASSWORD
                    </Link>
                    <br />
                    <Link to="/profile-edit" className="smallLink">
                      <i className="las la-edit"></i>EDIT CONTACT INFORMATION
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-7" style={{ borderLeft: 'solid 1px' }}>
                <h5>HOW DOES IT WORK?</h5>
                <div className="row">
                  <div className="col-lg-4 smallText">
                    Getting started with the Elevator Design Studio (EDS) is
                    easy. In 10 simple steps, this tutorial will show you how to
                    select your elevator interior configuration, apply materials
                    and finishes, view your progress with realistic renderings,
                    and manage your project from a single location.
                    <Link to="/how-does-it-work">MORE</Link>
                  </div>
                  <div className="col-lg-8">
                    <Link to="/how-does-it-work">
                      <img src={hditThumb} alt="Getting Started" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{
                borderBottom: 'solid 1px',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            ></div>
            <div className="row">
              <div className="col-lg-5">
                <h5>CREATE A NEW PROJECT</h5>
                <button
                  style={{
                    display: 'block',
                    outline: 'none',
                    width: '364px',
                    height: '148px',
                    border: 'solid 1px',
                    cursor: 'pointer',
                    background: 'url(' + newProjectButton + ')',
                    backgroundRepeat: 'none',
                    backgroundPosition: 'center center',
                  }}
                  className="fadeHover"
                  onClick={() => this.setState({ lightBoxOpen: true })}
                  alt="New Project"
                  title="Create a New Project"
                />
              </div>
              <div className="col-lg-7">
                <h5>MY PROJECTS</h5>
                <p className="smallText">
                  Just click on any project thumbnail to open it and access its
                  corresponding Elevator Design Studio (EDS) designs, or start
                  an entirely new project. Saved projects icons allow you to
                  directly access some project-specific features such as; edit,
                  create new EDS design, duplicate, and delete.
                </p>
                <div className="row">
                  <div className="col-s-4 col-xs-6">
                    <h5>SORTING</h5>
                    <div className="row">
                      <div className="col-lg-12">
                        <button
                          title="Sort by Creation Date Ascending"
                          className="filterButton"
                          onClick={() => this.sortProjects('created', 'asc')}
                        >
                          <i className="las la-sort-numeric-up"></i>
                        </button>
                        <button
                          title="Sort by Creation Date Descending"
                          className="filterButton"
                          onClick={() => this.sortProjects('created', 'desc')}
                        >
                          <i className="las la-sort-numeric-down"></i>
                        </button>
                        <button
                          title="Sort by Alphabetical Ascending"
                          className="filterButton"
                          onClick={() => this.sortProjects('name', 'asc')}
                        >
                          <i className="las la-sort-alpha-up"></i>
                        </button>
                        <button
                          title="Sort by Alphabetical Descending"
                          className="filterButton"
                          onClick={() => this.sortProjects('name', 'desc')}
                        >
                          <i className="las la-sort-alpha-down"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-6">
                    <h5>SEARCH PROJECTS</h5>
                    <input
                      type="text"
                      placeholder="Enter Project Name"
                      onChange={(e, val) => this.filterProjects(e)}
                    ></input>
                  </div>
                  <div className="col-sm-4 col-xs-12">{convertButton}</div>
                </div>
              </div>
            </div>
            <ProjectList
              projects={this.state.myprojectsFiltered}
              handleDelete={this.handleDelete}
              handleCreateDesign={this.handleCreateDesign}
              data={this.props.data}
              loadingProjects={this.state.loadingProjects}
            />
            {this.state.moreProjects && (
              <button
                onClick={() => {
                  this.setState(
                    {
                      skip: this.state.skip + this.state.limit,
                      loadingProjects: true
                    },
                    function () {
                      this.loadProjects(this.state.queryUser ? this.state.queryUser : null, false)
                    }
                  )
                }}
              >
                Load More Projects...
              </button>
            )}
          </div>
          {this.state.lightBoxOpen && (
            <Lightbox
              mainSrc=<NewProject
                configuration={this.state.configuration}
                handleUpdate={this.updateProjectName}
                handleSubmit={this.submitNewProject}
              />
              onCloseRequest={() => this.setState({ lightBoxOpen: false })}
              enableZoom={false}
              clickOutsideToClose={true}
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default Profile
